<template>
    <div class="content">
        <div class="row">

            <div class="col-md-12">
                <vuestic-widget :headerText="$t('view.device.title')">
                    <p>{{$t('view.device.description')}}</p>
                    <div class="row">
                        <div class="col-md-12 pull-right">
                            <button type="button" v-on:click="newDevice" class="btn btn-primary btn-sm">{{$t('view.device.new')}}</button>
                        </div>
                    </div>
                </vuestic-widget>
            </div>
            <div class="col-md-12">
                <table-device></table-device>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue'
import TableDevice from '../tables/TableDevice/TableDevice.vue'

import { SpringSpinner } from 'epic-spinners'

export default {
  name: 'Devices',
  components: {
    SpringSpinner,
      TableDevice
  },
  data () {
    return {}
  },
  methods: {
    newDevice () {
      this.$router.push('device/new')
    }

  }
}
</script>

<style lang="scss">

    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }
</style>
